.sheet-operation-progress-input .sheet-operation-progress-input__toggle-button-group {
    display: flex;
}

.sheet-operation-progress-input .sheet-operation-progress-input__toggle-button {
    flex: 1 0 auto;
    width: calc(50% - 0.25rem);
    border: 0;
    padding: .5rem 1rem;
    font-size: 1.4rem;
    line-height: .9;
    text-transform: none;
}

.sheet-operation-progress-input .sheet-operation-progress-input__toggle-button-group button.sheet-operation-progress-input__toggle-button {
    border-radius: .3rem;
}

.sheet-operation-progress-input .sheet-operation-progress-input__toggle-button-group .sheet-operation-progress-input__toggle-button:not(:first-of-type) {
    margin-left: 0.5rem;
}

.sheet-operation-progress-input__wrapper {
    display: block;
}

.sheet-operation-progress-input .text-form-field__text-field-input-wrap,
.sheet-operation-progress-input label {
    font-size: 1.8rem;
}