.split-entity-batch-dialog__initial-batch-size {
    margin-bottom: 3rem;
    display: flex;
}

.split-entity-batch-dialog__initial-batch-size-value {
    margin-left: 1rem;
    display: inline-flex;
    align-items: center;
}

.split-entity-batch-dialog__initial-batch-size-value-arrow-icon{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.split-entity-batch-dialog__batch-size{
    margin-bottom: 3rem;
}

.split-entity-batch-dialog__batch-size-label{
    margin-bottom: 0.5rem;
}

.split-entity-batch-dialog__batch-size-text-field input{
    padding: 1.2rem;
    font-size: 1.6rem;
}

#split-entity-batch-dialog__batch-size-text-field-helper-text,
#split-entity-batch-dialog__split-reason-textarea-helper-text{
    font-size: 1.2rem;
    position: absolute;
    bottom: -2.2rem;
    margin-left: 0;
}

.split-entity-batch-dialog__split-reason-label{
    margin-bottom: 0.5rem;
}

.split-entity-batch-dialog__split-reason-textarea > div{
    font-size: 1.6rem;
    padding: 1.2rem;
}

#split-entity-batch-dialog__split-reason-textarea{
    line-height: 2rem;
}