.entities-review-table .entities-review-table__list-item{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-clip: padding-box;
    padding: 2rem 2.8rem;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.entities-review-table .entities-review-table__list-item:first-child{
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.entities-review-table__no-data-content{
    text-align: center;
    font-size: 1.5rem;
}

.entities-review-table__columns-filters-wrap{
    margin-bottom: 1.4rem;
}

.entities-review-table-filters .filters button{
    font-size: 1.4rem;
    padding: 0.8rem 1.6rem;
}

.entities-review-table-filters .filters__chip span{
    font-size: 1.4rem;
}

.entities-review-table__top-table-pagination-with-table-menu-wrap,
.entities-review-table__bottom-table-pagination-wrap{
    padding: 0;
}