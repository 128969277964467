.table-from-factory__title-wrap{
    text-align: center;
    margin: 1rem 0 2.3rem 0;
}

.table-from-factory__top-table-pagination-with-table-menu-wrap{
    padding: 0.4rem;
    display: flex;
    justify-content: space-between;
}

.table-from-factory__top-table-pagination-with-table-menu-wrap--flex-end{
    justify-content: flex-end;
}

.table-from-factory__bottom-table-pagination-wrap{
    padding: 0.4rem;
}