.all-sheets-to-start__no-sheets-to-start-label {
    font-size: 1.5rem;
    text-align: center;

    /*
    Этот паддинг нужен для выравнивания лейбла отстуствия основного расчета по вертикали на одном уровне с лейблами об
    отсуствии табличных данных на соседних вкладках. Возможно, в последствии будет лучше реализовать тут какое-то другое
    выравнивание (по центру).
    */
    padding-top: 1.4rem;
}

.all-sheets-to-start__date-time-interval-filters{
    margin-bottom: 3rem;
}

.all-sheets-to-start__date-time-interval-filters-label {
    font-weight: bold;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    margin-top: -1.7rem;
}
