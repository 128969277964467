.worker-tasks-table-settings-edit-form{
    background: #fff;
    padding: 3rem;
    box-shadow: 0 0.1rem 0.5rem 0 rgba(0, 0, 0, 0.2), 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.14), 0 0.3rem 0.1rem -0.2rem rgba(0, 0, 0, 0.12);
}

.worker-tasks-table-settings-edit-form--read-only{
    background: inherit;
    box-shadow: none;
    padding: 3rem 0;
}