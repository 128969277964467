:root {
  --toastify-color-success: #e8f5e9;
  --toastify-color-info: #e3f2fd;
  --toastify-color-warning: #fff8e1;
  --toastify-color-error: #fbe9e7;

  --toastify-toast-width: 300px;
  --toastify-toast-min-height: 4.7rem;

  --toastify-z-index: 9999999;

  --toastify-success-darker: #81c784;
  --toastify-info-darker: #64b5f6;
  --toastify-warning-darker: #ffe082;
  --toastify-error-darker: #ff8a65;
}

.Toastify__toast--success .Toastify__close-icon,
.Toastify__toast--success .Toastify__toast-body:before {
  color: var(--toastify-success-darker);
}

.Toastify__toast--info .Toastify__close-icon,
.Toastify__toast--info .Toastify__toast-body:before {
  color: var(--toastify-info-darker);
}

.Toastify__toast--warning .Toastify__close-icon,
.Toastify__toast--warning .Toastify__toast-body:before {
  color: var(--toastify-warning-darker);
}

.Toastify__toast--error .Toastify__close-icon,
.Toastify__toast--error .Toastify__toast-body:before {
  color: var(--toastify-error-darker);
}

.Toastify__close-icon {
  position: absolute;
  top: .4rem;
  right: .4rem;
  width: 1.7rem;
  height: 1.7rem;
}

.Toastify__close-icon svg {
  width: 1.7rem;
  height: 1.7rem;
}

.Toastify__toast {
  font-family: inherit;
  font-size: 1.5rem;
  border-radius: 0.2rem;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast-body:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background: currentColor;
}

.Toastify__toast-body, .Toastify__close-button {
  color: rgba(0, 0, 0, .7);
}