.planner-workspace{
    margin: 4rem 12rem 12rem 12rem;
}

.planner-workspace__control-panel-wrapper {
    display: flex;
    justify-content: flex-end;
}

.planner-workspace__content{
    margin-top: 7rem;
}

.planner-workspace__view-mode-toggle-btn-group button {
    width: 15rem;
    color: #333;
    padding: 0.7rem;
}

.planner-workspace__view-mode-toggle-btn-group button {
    font-size: 1.3rem;
}

.planner-workspace .planner-workspace__view-mode-toggle-btn-group .planner-workspace__view-mode-toggle-btn--selected {
    background-color: #2196f3;
    color: white;
}

.planner-workspace .planner-workspace__view-mode-toggle-btn-group .planner-workspace__view-mode-toggle-btn--selected:hover {
    background-color: rgba(33, 150, 243, 0.8);
}