.edit-form-control-buttons-panel{
    text-align: right;
}

.edit-form-control-buttons-panel button{
    margin-left: 2rem;
}

.edit-form-control-buttons-panel__submit-disable-reason-text{
    font-size: 1rem;
    margin-bottom: 1rem;
}