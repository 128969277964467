.common-pagination button{
    font-size: 1.6rem;
    color: #2196f3;
    padding: 0.6rem 1.2rem;
    height: 4rem;
}

.common-pagination button.Mui-selected{
    color: #2196f3;
    background-color: rgba(33, 150, 243, 0.2);
}

.common-pagination button:hover,
.common-pagination button.Mui-selected:hover{
    color: #2196f3;
    background-color: rgba(33, 150, 243, 0.04);
}

.common-pagination button svg {
    width: 2rem;
    height: 2rem;
}

.common-pagination .MuiPaginationItem-ellipsis{
    font-size: 1.6rem;
    padding: 0.6rem 0.4rem;
    margin: 0;
    min-width: unset;
    color: rgba(0, 0, 0, 0.26);
}