.tasks-table-columns-settings-group{
    margin-bottom: 6rem;
}

.tasks-table-columns-settings-group__title{
    font-size: 1.4rem;
}

.tasks-table-columns-settings-group__columns-droppable-container{
    display: flex;
    overflow: auto;
}

.tasks-table-columns-settings-group__columns-droppable-container--is-dragging-over{
    background: #f9f9f9;
}

.tasks-table-columns-settings-group__columns-list{
    display: flex;
    padding: 3rem 0.7rem;
}

.tasks-table-columns-settings-group__draggable-column-item{
    user-select: none;
    padding: 2rem;
    margin: 0 1rem;
    background: rgba(0,0,0,0.03);
    box-shadow: 0 0.1rem 0.5rem 0 rgba(0, 0, 0, 0.2), 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.14), 0 0.3rem 0.1rem -0.2rem rgba(0, 0, 0, 0.12);
    width: 20rem;
    display: flex;
    align-items: center;
}

.tasks-table-columns-settings-group__draggable-column-item--is-dragging{
    background: #2196f3;
}

.tasks-table-columns-settings-group__draggable-column-content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.tasks-table-columns-settings-group__draggable-column-title{
    margin-right: 3rem;
}

.tasks-table-columns-settings-group__draggable-column-item .tasks-table-columns-settings-group__draggable-column-visibility-checkbox svg{
    width: 2.4rem;
    height: 2.4rem;
}