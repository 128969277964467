.worker-tasks-table-settings-control-panel--disabled{
    opacity: 0.5;
    pointer-events: none;
}

.worker-tasks-table-settings-control-panel__create-new-settings-buttons{
    margin-bottom: 3rem;
}

.worker-tasks-table-settings-control-panel__create-new-settings-buttons button{
    margin-right: 2rem;
}

.worker-tasks-table-settings-control-panel__settings-select-with-controls-wrap{
    display: flex;
    margin-bottom: 3rem;
}

.worker-tasks-table-settings-control-panel__settings-select-with-controls-wrap button{
    margin-left: 2rem;
}

.worker-tasks-table-settings-control-panel__settings-select{
    flex: 99999 0 auto;
}