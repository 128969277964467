.tasks-owner-choose-screen__tasks-owner-card-main-title,
.tasks-owner-choose-screen__tasks-owner-card-secondary-title{
    overflow: hidden;
    text-overflow: ellipsis;
}

.tasks-owner-choose-screen__tasks-owner-card-main-title{
    font-size: 2.5rem;
    margin-bottom: 4rem;
    font-weight: bold;
}

.tasks-owner-choose-screen__tasks-owner-card-secondary-title-label{
    margin-right: 1rem;
    font-size: 1.5rem;
}

.tasks-owner-choose-screen__tasks-owner-card-secondary-title-value{
    font-weight: bold;
    font-size: 1.7rem;
}